.formElement {
  margin-bottom: 0.15em;
}

.rightContainer>.formElement:first-child {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.formElement label,
.formElement span {
  display: block;
  padding: 0.5em 0;
}

.formElement+.formElement {
  padding-top: 1.25em;
}

.iconLabelRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.infoIcon {
  margin-left: 5px;
  position: relative;
}

.iconInfoBox {
  position: absolute;
  z-index: 2;
  top: -50%;
  left: 120%;
  transform: translateX(5%);
  max-width: 350px;
  min-width: 200px;
  background-color: var(--colorGrey700);
  color: var(--colorWhite);
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.3px;
  border: 1px solid var(--colorGrey);
  border-radius: var(--borderRadiusMedium);
  padding: 12px;
  box-shadow: var(--boxShadowPopup);
  display: none;
}

.infoIcon:hover .iconInfoBox {
  display: block;
}

.inputContainer {
  background-color: #f1f2f3;
  border-radius: 6px;
  border: 1px solid #d8dce6;
  outline: none;
  padding: 6px 12px 4px 12px;
  width: min(calc(100vw - 40px), 100vw);
}

.commentContainer {
  height: 96px;
  background-color: #f1f2f3;
  border-radius: 6px;
  border: 1px solid #d8dce6;
  outline: none;
  padding: 6px 12px 4px 12px;
  width: min(calc(100vw - 40px), 100vw);
}

@media (min-width: 651px) {

  .inputContainer,
  .commentContainer {
    width: min(calc(100vw - 480px), 100vw);
  }
}

.input {
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.3px;
  color: var(--colorGrey700);
}

.setPriceContainer {
  border-radius: 6px;
  padding: 0px;
  width: 350px;
}

.setPriceInput {
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.3px;
  color: var(--colorGrey700);
}