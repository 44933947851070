.cookieBanner {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    max-width: 400px;
    padding: 16px;
    background: white;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 24px;
    z-index: 1000;
}

@media (min-width: 768px) {
    .cookieBanner {
        left: auto;
        right: 20px;
        transform: none;
        width: 400px;
    }
}

.content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.title {
    align-self: stretch;
    color: black;
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    line-height: 25px;
    margin: 0;
}

.description {
    align-self: stretch;
    color: black;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.link {
    color: black;
    text-decoration: underline;
}

.buttons {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.rejectButton,
.acceptButton {
    flex: 1;
    height: 44px;
    padding: 10px 16px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 24px;
    border: 1px solid;
    cursor: pointer;
}

.rejectButton {
    background: white;
    color: #344054;
    border-color: #D0D5DD;
}

.acceptButton {
    background: #151515;
    color: white;
    border-color: #151515;
}