@import '../../styles/customMediaQueries.css';

.root {
  display: none;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding-left: 25px;

  @media (--viewportMedium) {
    padding-left: 5px;
  }


  &:first-child {
    margin-left: 25;
  }
}
