.root {}

.selectError {
  border: var(--borderErrorField);
}

.iconLabelRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.infoIcon {
  margin-top: -10px;
  margin-left: 5px;
  position: relative;

  @media (max-width: 768px) {
    margin-top: -5px;
  }
}

.iconInfoBox {
  position: absolute;
  z-index: 2;
  top: -50%;
  left: 120%;
  transform: translateX(5%);
  max-width: 450px;
  min-width: 300px;
  background-color: var(--colorGrey700);
  border: 1px solid var(--colorGrey);
  border-radius: var(--borderRadiusMedium);
  padding: 12px;
  box-shadow: var(--boxShadowPopup);
  display: none;

  @media (max-width: 1200px) {
    top: 100%;
    left: 50%;
    transform: translateX(-35%);
  }
}

.infoIcon:hover .iconInfoBox {
  display: block;
}

.iconInfoBox ul {
  padding-left: 12.5px;
  color: var(--colorWhite);
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.iconInfoBox li {
  margin-bottom: 10px;
  color: var(--colorWhite);
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.iconInfoBox strong {
  font-weight: 600;
  color: var(--colorWhite);
  font-family: 'Century Gothic', sans-serif;
  font-style: normal;
  font-size: 13px;
}