@import '../../styles/customMediaQueries.css';

.root {
  display: block;
  flex-shrink: 0;
  padding-top: 8px;
  width: 60%;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 0 24px;
    width: auto;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
    width: auto;
  }
}
