@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transitionStyleButton);
  background: white;
  max-width: 216px;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
    }
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: auto; 
}

.rootForImage {
  max-width: 216px;
  width: auto; 
  height: auto;
    align-self: center;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 15px;
  margin-bottom: 4px;
}

.priceValue {
  font-family: 'Avenir', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #1c1b1b;

  margin-top: 2px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 0;
  }
}

.originalPriceValue {
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #1c1b1b;
  text-decoration: line-through;
  text-align: center;

  margin-top: 2px;
  margin-bottom: 0;
  padding-left: 10px;

  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 0;
    padding-left: 10px;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  height: 150px;
}

.brandName {
  font-family: 'Avenir Pro Black', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: grey;
  margin-top: 10px;
  margin-bottom: 20px;
}

.title {
  font-family: 'Avenir Pro Black', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #1c1b1b;
  margin-bottom: 10px;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.brandName {
  margin-top: 10px;
  margin-bottom: 20px;
}